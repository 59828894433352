footer {
  background-color: $footer-bg-color;
  color: $footer-font-color;

  .btn-link,
  .btn-link:hover,
  a,
  a:hover {
    color: $footer-font-color;
  }
  svg {
    height: 2rem;
    width: auto;
    margin-bottom: 1rem;
    path {
      fill: $footer-font-color;
      margin-right: auto;
    }
  }
}
