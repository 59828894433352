.bst-section-large {
  padding: 5em 0;
}

.bst-section-medium {
  padding: 2.5em 0;
}

.bst-section-small {
  padding: 1.5em 0;
}

.bst-border-bottom {
  border-bottom: 1px solid $h-line-color;
}

.bst-border-top {
  border-top: 1px solid $h-line-color;
}

.bst-section-primary {
  background-color: $primary;
  color: $section-primary-color;

  a:not(.btn),
  a:hover:not(.btn),
  pre,
  .btn-link,
  .btn-link:hover {
    color: inherit;
  }

  .icon svg {
    fill: $section-primary-color;
  }
}

.bst-section-secondary {
  background-color: $secondary;
  color: $section-secondary-color;

  a:not(.btn),
  a:hover:not(.btn),
  pre {
    color: inherit;
  }

  .icon svg {
    fill: $section-secondary-color;
  }
}

.bst-section-dark {
  background-color: $black;
  color: $offwhite;

  a:not(.btn),
  a:hover:not(.btn),
  pre {
    color: inherit;
  }

  .icon svg {
    fill: $offwhite;
  }
}

.bst-section-light {
  background-color: $offwhite;

  .btn-light {
    background-color: white;
  }
}
