header {
  position: relative;
  background-color: $black;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $img-overlay-color;
    opacity: $img-overlay-opacity;
    z-index: 1;

    &:hover {
      opacity: $img-overlay-opacity !important;
    }
  }

  .carousel-item,
  .bst-header-img {
    min-height: 25vh;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover, 10em;
  }

  .carousel-item {
    height: 65vh;
  }

  .bst-header-img {
    height: 25vh;

    &.large {
      height: 65vh !important;
    }
  }

  .carousel-flex-container,
  .bst-header-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    &.center-container {
      margin: 0 auto;
      width: 75%;
    }
  }

  .carousel-caption,
  .bst-header-caption {
    color: $offwhite;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    &.center-caption {
      text-align: center;
      margin: auto;
    }
  }
}
