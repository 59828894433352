// Font family

//$font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-base:       $font-family-sans-serif;

@font-face {
  font-family: Alice;
  src: url(../../assets/fonts/Alice-Regular.ttf) format("truetype");
}
$headings-font-family: "Alice", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-weight: 600;
/* 
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem; */

$enable-responsive-font-sizes: true;

// Color variations

$primary: #5863f8;
$secondary: #2d2e2e;

$white: #fff;
$offwhite: #f5f5f5;
$gray-100: $offwhite;
$black: #222;

$body-bg: $white;
$body-color: $black; // text color

$h-line-color: lightgrey;

// for navbar dropdown menu
$dropdown-link-active-bg: darken($offwhite, 10%);

// Navbar
$navbar-bg-color: $offwhite;
$navbar-light-color: $secondary;
$navbar-light-hover-color: #828afa;
$navbar-light-active-color: $primary;

// Image overlay
$img-overlay-color: $black;
$img-overlay-opacity: 0.65;

// Sections
$section-primary-color: $offwhite;
$section-secondary-color: $offwhite;

// Footer
$footer-bg-color: darken($offwhite, 10%);
$footer-font-color: $black;
